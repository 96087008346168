<template>
  <Modal
    id="deliveryModal"
    :isOpen.sync="isModalOpen"
    :header="modalTitle"
    size="is-medium"
    :loading="loading"
    @cancel="() => {}"
    @save="onSubmit"
    :saveDisabled="isSomethingLoading"
  >
    <Tags :loading="loading.addresses || loading.participant" :value="topTags" />
    <form ref="form" class="deliveryForm">
      <div class="columns">
        <DatePicker
          v-model="oDelivery.delivery_date"
          class="column is-4"
          :disabled="isAddOn"
          :label="$t('schedule.search.date')"
          :min-date="minDate"
          required
        />
        <div class="flex is-align-items-center mt-5 ml-4">
          <iSwitch
            v-model="oDelivery.is_collect_event"
            :text="$t('global.collect')"
            :disabled="leaveAtDoor"
          />
        </div>
      </div>
      <p class="subTitle">{{ $t('schedule.pickupInfo') }}</p>
      <div class="columns">
        <Autocomplete
          class="column cpdField"
          :label="$t('menu.cdp')"
          :api="{
            url: 'cdp',
            query: 'name',
            full: true,
            params: [{ id: 'is_active', value: 1 }]
          }"
          v-model="oDelivery.close_door_pharmacy_id"
          model="id"
          @select="
            (id) => {
              if (id) oDelivery.close_door_pharmacy_id = id;
            }
          "
          setFirst
          tabindex="-1"
          field="name"
          required
        >
          <template #results="{ data: CDP }">
            <div class="cdpResult">
              <strong>{{ CDP.name }}</strong>
              <br />
              <small>{{ CDP.full_address }}</small>
            </div>
          </template>
        </Autocomplete>
      </div>
      <div class="columns">
        <Field
          class="column is-6"
          :label="$t('global.orderNumber')"
          :maxlength="15"
          v-model="oDelivery.tracking_number"
          focus
          required
          ref="order_number"
        >
          <template #validation-message>
            <small class="order-number-text"> {{ $t('modal.orderNumber') }}</small>
          </template>
        </Field>
        <CopaymentField
          class="column is-6"
          v-model="oDelivery.copayment_amount"
          :disabled="!!oDelivery.is_collect_event"
        />
      </div>
      <p class="subTitle">{{ $t('participant.infoLabel') }}</p>
      <div class="columns">
        <Autocomplete
          class="column is-full"
          :key="`PA-${customParticipantKey}`"
          @select="onParticipantSelect"
          :label="$tc('participant.label', 1)"
          :api="{
            url: 'participants/search',
            query: 'all',
            params: [{ id: 'is_active', value: 1 }]
          }"
          @add="onParticipantAdd"
          field="participant_full_name"
          :dataPreProcessor="participantDataPreProcessor"
          :localFiltering="false"
          :isDisabledAddButton="disabled.addParticipantButton"
          :sortData="false"
        >
          <template #results="{ data }">
            <div>
              <strong>{{ data.participant_full_name }}</strong>
              <br />
              {{ data.participant_full_address }}
            </div>
          </template>
        </Autocomplete>
      </div>
      <EditParticipant
        :key="`PF-${customParticipantKey}`"
        :focus="customParticipantKey > 1"
        :participantId="oDelivery.participant_id"
        :value="data.participant"
        :loading.sync="loading.phones"
        @change="handleCustomParticipant"
        @updatedPhones="(phones) => (this.phones = phones)"
        extraDetails
      >
        <template #afterNames>
          <div class="address-container">
            <div class="columns">
              <AddressSelector
                class="column is-full"
                :key="`AS-${oDelivery.participant_id}`"
                :label="$t('schedule.delivery')"
                :data="addresses"
                :loading="loading.addresses"
                :value="deliveryAddress"
                @select="onSelectAddress"
                field="full_address"
                model="id"
                :isDisabledEditButton="disabled.buttonIsAddress"
                :required="!show.formAddressPicker"
                @add="onAddAddress"
                @edit="onEditAddress"
                @cancel="onCancelAddress"
                :initAdd="hasParticipantError"
              />
            </div>
            <div class="columns" v-if="show.formAddressPicker">
              <AddressPicker
                class="column is-full"
                ref="addressPicker"
                v-model="address"
                :isEdit="action.isEdit"
                @change="updateAddress"
                useExtraInfoComment
              />
            </div>
          </div>
        </template>
      </EditParticipant>
      <div class="columns">
        <Field
          class="column is-full"
          :label="$tc('global.comment', 2)"
          v-model="oDelivery.comments"
          type="textarea"
          :rows="2"
          :placeholder="$t('global.placeholder.deliveryComment')"
        />
      </div>
      <p class="subTitle">{{ $t('dispatch.deliverySequence') }}</p>
      <div class="flex deliverySequence columns">
        <iSwitch
          boolean
          class="is-one-third column"
          v-model="rush"
          :text="$t('global.rush')"
          :disabled="priority"
        />
        <iSwitch
          boolean
          class="is-one-third column"
          v-model="leaveAtDoor"
          :text="$t('global.leave-at-door')"
          :disabled="!!oDelivery.is_collect_event"
        />
        <iSwitch
          boolean
          class="is-one-third column"
          v-model="priority"
          :text="$t('global.priority')"
          :disabled="rush"
        />
        <iSwitch
          class="is-one-third column"
          v-model="oDelivery.refrigeration_needed"
          :text="$t('global.refrigerated')"
        />
        <iSwitch
          class="column w-100"
          v-model="hasRestriction"
          :text="$t('deliveries.restriction')"
        />
      </div>
      <DeliverRestrictions
        v-if="hasRestriction"
        :value="constraintDates"
        @change="onDeliveryTimesChange"
        :offset="oDelivery.offset_tz"
        UTC
      />
    </form>
  </Modal>
</template>

<script>
import {
  AddressPicker,
  AddressSelector,
  Autocomplete,
  CopaymentField,
  DatePicker,
  DeliverRestrictions,
  EditParticipant,
  Field,
  iSwitch,
  Tags
} from '../../components';
import Modal from '@/components/Modal.vue';
import { toast, confirm } from '@/utils/dialog';
import { Normalize } from '@/utils/Text';
import { multipleSpacesRegex } from '../../utils/RegexValidations';
import { exceptionMessage } from '../../utils/Helpers';
import RSC from '@/utils/RequestStatusCodes';

export default {
  components: {
    AddressPicker,
    AddressSelector,
    Autocomplete,
    CopaymentField,
    DatePicker,
    DeliverRestrictions,
    EditParticipant,
    Field,
    iSwitch,
    Modal,
    Tags
  },
  created() {
    this.$store.subscribe(({ type }, { dispatch: { manifest } }) => {
      if (type === 'dispatch/manifest') {
        this.myManifestId = manifest.id;
      }
    });
  },
  beforeDestroy() {
    this.$emit('update:isOpen', false);
  },
  data() {
    return {
      action: { isEdit: false, isAdd: false },
      address: {},
      addresses: [],
      currentDeliveryParticipantId: null,
      data: { participant: {}, newAddress: {}, idAddress: null },
      deliveryAddress: null,
      dateEnd: null,
      dateStart: null,
      deliveryTimes: null,
      disabled: { addParticipantButton: true, buttonIsAddress: true },
      hasRestriction: null,
      isModalOpen: false,
      landlinePhone: {},
      loading: { save: false, get: false, addresses: false, participant: false, phones: false },
      mobilePhone: {},
      myManifestId: null,
      oDelivery: { delivery_date: '', participant_full_name: '', participant_address_id: null },
      customParticipantKey: 1,
      customParticipant: null,
      phones: [],
      rush: false,
      priority: false,
      leaveAtDoor: false,
      show: { formAddressPicker: false },
      hasParticipantError: null
    };
  },
  computed: {
    isImport() {
      return this.scope === 'IMPORT';
    },
    minDate() {
      return this.$moment(new Date()).subtract(1, 'days').toDate();
    },
    isSomethingLoading() {
      const { loading } = this;
      return Object.keys(loading).some((key) => loading[key]);
    },
    modalTitle() {
      if (this.isAddOn) return this.$t('dispatch.addOn');
      return this.oDelivery.id ? this.$t('schedule.editDelivery') : this.$t('schedule.newDelivery');
    },
    mobilePhones() {
      return this.phones.filter((phone) => phone.number_type === 'mobile');
    },
    landPhones() {
      return this.phones.filter((phone) => phone.number_type === 'land');
    },
    mobilePhoneNumberId() {
      const cpPhoneId = this.customParticipant?.mobile_phone_number_id;
      const { participant: P } = this.data;
      const currentPhone = P?.participant_mobile_number || P?.participant_mobile_phone_number;
      const Phone = this.mobilePhones.find((phone) => phone.phone_number === currentPhone);
      return cpPhoneId || Phone?.id || null;
    },
    participantId() {
      return this.customParticipant?.temp?.id || this.data?.participant?.id;
    },
    phoneNumberId() {
      const cpPhoneId = this.customParticipant?.phone_number_id;
      const { participant: P } = this.data;
      const currentPhone = P?.participant_number || P?.participant_phone_number;
      const Phone = this.landPhones.find((phone) => phone.phone_number === currentPhone);
      return cpPhoneId || Phone?.id || null;
    },
    currentAddressId() {
      return this.data.idAddress || this.oDelivery.participant_address_id;
    },
    currentAddress() {
      return this.addresses.find((a) => a.id === this.currentAddressId);
    },
    constraintDates() {
      const { oDelivery, permanentConstraint } = this;
      const { delivery_start_time: DST, delivery_end_time: DET } = oDelivery;
      const { start_time: PST, end_time: PET, active } = permanentConstraint;
      if (this.delivery?.id && !this.isDifferentParticipant)
        return {
          type: 'delivery',
          active: !!DST || !!DET,
          start_time: DST || 0,
          end_time: DET || 0
        };
      return {
        type: 'permanent',
        active,
        start_time: PST || 0,
        end_time: PET || 0
      };
    },
    permanentComment() {
      const { participant_permanent_comment } = this.data?.participant || {};
      return participant_permanent_comment || '';
    },
    permanentConstraint() {
      const { participant_delivery_start_time, participant_delivery_end_time } =
        this.data?.participant || {};
      const active = !!participant_delivery_start_time || !!participant_delivery_end_time;
      return {
        active,
        start_time: participant_delivery_start_time,
        end_time: participant_delivery_end_time
      };
    },
    isDifferentParticipant() {
      return this.currentDeliveryParticipantId !== this.oDelivery.participant_id;
    },
    topTags() {
      const tags = [];
      const { isDifferentParticipant, permanentConstraint, currentAddress, permanentComment } =
        this;
      if (isDifferentParticipant || this.isImport) {
        if (permanentComment)
          tags.push({ text: this.$t('participant.pComment'), tooltip: permanentComment });
        if (permanentConstraint.active) tags.push({ text: this.$t('participant.PDR') });
      }
      if (currentAddress?.gate_code)
        tags.push({ text: this.$t('global.gateCode'), tooltip: currentAddress?.gate_code });
      return tags;
    }
  },

  methods: {
    setDefaultDate() {
      const defaultDate = this.$moment();
      if (this.manifestId) this.myManifestId = this.manifestId;
      // if (!this.isAddOn) {
      //   defaultDate.add(1, 'day');
      // }
      this.oDelivery.delivery_date = defaultDate.format('Y-MM-DD');
    },
    handleCustomParticipant(participant) {
      this.customParticipant = participant;
    },
    updateAddress(address) {
      this.data.newAddress = address;
    },
    filterDefaultPhone(phones = [], type) {
      return phones.filter((P) => P.number_type === type).find((P) => P.is_default);
    },
    participantDataPreProcessor(data) {
      const processedData = data.map(({ _source: D }) => {
        const mobile = this.filterDefaultPhone(D.participant_phone_number, 'mobile');
        const land = this.filterDefaultPhone(D.participant_phone_number, 'land');
        const address = D.participant_address.find((pa) => pa.is_default);
        const participantAddress = {
          participant_full_address: address?.full_address,
          participant_address_id: address?.address_id,
          participant_address: address?.address,
          participant_address_1: address?.address_1,
          participant_address_gate_code: address?.gate_code,
          participant_address_city: address?.city,
          participant_address_latitude: address?.latitude,
          participant_address_longitude: address?.longitude,
          participant_address_zipcode: address?.zipcode,
          participant_address_state: address?.state
        };
        return {
          ...D,
          participant_phones: D.participant_phone_number,
          participant_phone_number: land?.phone_number,
          participant_mobile_phone_number: mobile?.phone_number,

          ...participantAddress
        };
      });
      return processedData;
    },
    onEditAddress() {
      this.show.formAddressPicker = true;
      this.action.isEdit = this.action.isEdit ? false : true;
      this.action.isAdd = false;
      if (this.data.idAddress) {
        const value = this.addresses.find((address) => {
          return address.id === this.data.idAddress;
        });
        const { address, address_1, city, gate_code, latitude, longitude, state, zipcode, id } =
          value;
        this.address = {
          address_1,
          address,
          city,
          gate_code,
          id,
          latitude,
          longitude,
          state,
          zipcode
        };
      } else {
        const P = this.data?.participant || {};
        this.address = {
          id: P.participant_address_id || this.oDelivery.participant_address_id,
          address: P.participant_address || this.oDelivery.participant_address,
          address_1: P.participant_address_1,
          gate_code: P.participant_address_gate_code,
          city: P.participant_address_city,
          zipcode: P.participant_address_zipcode,
          latitude: P.participant_address_latitude,
          longitude: P.participant_address_longitude,
          state: P.participant_address_state
        };
      }
    },
    async onAddAddress() {
      this.show.formAddressPicker = true;
      if (this.action.isEdit) {
        this.show.formAddressPicker = true;
        this.setAddress(null, null);
        this.disabled.buttonIsAddress = true;
      }

      if (!this.hasParticipantError) {
        this.address = {
          address: '',
          address_1: '',
          city: null,
          zipcode: null,
          latitude: 0,
          longitude: 0,
          state: 'CA'
        };
      }
      this.action.isEdit = false;
      this.action.isAdd = true;
    },
    onCancelAddress() {
      this.show.formAddressPicker = false;
      this.hasParticipantError = false;
      this.action.isEdit = false;
      this.action.isAdd = false;
    },
    onDeliveryTimesChange({ start_time, end_time, display }) {
      this.deliveryTimes = {
        delivery_start_time: start_time,
        delivery_end_time: end_time,
        display
      };
    },
    async getAddresses() {
      this.addresses = [];
      this.loading.addresses = true;
      const PAI = this.oDelivery.participant_address_id;
      try {
        const { data } = await this.Api.get(
          `participants/${this.oDelivery.participant_id}/addresses`
        );
        this.addresses = data;
        if (!this.participantAddress && !this.hasParticipantError) {
          const addressByDelivery = data.find(({ id }) => id === PAI);
          const addressByDefault = data.find(({ is_default }) => is_default);
          const oAddress = addressByDelivery || addressByDefault || data[0];
          if (oAddress) {
            this.setAddress(oAddress.id, oAddress.full_address);
            this.show.formAddressPicker = false;
            this.disabled.buttonIsAddress = false;
            if (this.action.isAdd) this.action.isAdd = false;
          } else {
            this.setAddress(null, null);
            this.disabled.buttonIsAddress = false;
          }
        }
      } catch (error) {
        console.error(error);
        this.addresses = [];
      }
      this.loading.addresses = false;
    },
    onSelectAddress(id) {
      if (id) {
        this.data.idAddress = id;
        this.oDelivery.participant_address_id = id;
        this.show.formAddressPicker = false;
        this.disabled.buttonIsAddress = false;

        if (!this.show.formAddressPicker && this.action.isEdit) {
          this.action.isEdit = false;
        }
        if (!this.show.formAddressPicker && this.action.isAdd) {
          this.action.isAdd = false;
        }
      }
    },
    onParticipantAdd() {
      this.data.participant = {};
      this.disabled.addParticipantButton = true;
      this.oDelivery.participant_full_name = '';
      this.customParticipantKey++;
    },
    onParticipantSelect(participant) {
      const isDifferent = this.oDelivery.participant_id !== participant?.id;
      if (participant && isDifferent) {
        this.disabled.addParticipantButton = false;
        this.data.participant = participant;
        this.deliveryAddress = isDifferent
          ? participant.participant_full_address
          : this.oDelivery.participant_full_address;
        this.oDelivery.participant_id = participant.id;
        this.hasRestriction = this.permanentConstraint.active;
        this.oDelivery.comments = this.permanentComment;
        this.getAddresses();
      }
    },
    async saveParticipantPhone({ id, ...payload }) {
      try {
        const { data } = await this.Api.post(`participants/${id}/phone_numbers`, payload);
        if (payload.number_type === 'land') this.customParticipant.phone_number_id = data.id;
        else this.customParticipant.mobile_phone_number_id = data.id;
      } catch (error) {
        console.log(error);
      }
    },
    async saveParticipantPhones() {
      const {
        mobile_phone_number_id,
        mobile_phone_number: MPN,
        phone_number_id,
        phone_number,
        temp
      } = this.customParticipant;
      const { id, full_name } = temp;
      const payload = { id, contact_name: full_name };

      if (phone_number && !phone_number_id)
        await this.saveParticipantPhone({ number_type: 'land', phone_number, ...payload });
      if (MPN && !mobile_phone_number_id)
        await this.saveParticipantPhone({ number_type: 'mobile', phone_number: MPN, ...payload });
    },
    async saveParticipant() {
      const P = this.customParticipant;
      const defaultData = {
        cbla: 0,
        client_type_id: 2,
        gender: 'Unspecified',
        is_blind: 0,
        is_deaf: 0,
        mentalillness: 0,
        needs_companion: 0,
        needs_translator: 0,
        needs_vehicle_ramp: 0,
        needs_walker: 0,
        participant_status_id: 1,
        safe_for_female: 0,
        space_type: 'AMB'
      };
      const { data } = await this.Api.post('/participants', {
        client_code: P.client_code,
        email: P.email,
        first_name: P.first_name,
        last_name: P.last_name,
        middle_name: P.middle_name,
        funding_source_id: P.funding_source_id,
        ...defaultData
      });
      this.customParticipant.temp = { id: data.id, full_name: data.full_name };
    },
    async updateParticipant() {
      const { id, ...payload } = this.customParticipant;
      delete payload.temp;
      const { data } = await this.Api.put(`participants/${id}/main_info`, payload);
      this.customParticipant.temp = { id: data.id, full_name: data.full_name };
      if (data.phone_number_id) this.customParticipant.phone_number_id = data.phone_number_id;
      if (data.mobile_phone_number_id)
        this.customParticipant.mobile_phone_number_id = data.mobile_phone_number_id;
    },
    async handleSaveParticipant() {
      if (!this.customParticipant) return;
      if (this.customParticipant?.id) {
        await this.updateParticipant();
      } else {
        await this.saveParticipant();
        await this.saveParticipantPhones();
      }
    },
    async editAddress() {
      try {
        const address = this.data.newAddress;
        const params = {
          address: address.address,
          address_1: address.address_1,
          state: address.state,
          city: address.city,
          gate_code: address.gate_code,
          zipcode: address.zipcode,
          latitude: address.latitude,
          longitude: address.longitude,
          formatted_address: address.formatted_address
        };

        await this.Api.put(
          `participants/${this.oDelivery.participant_id}/addresses/${this.address.id}`,
          params
        );
      } catch (error) {
        console.error(error);
        this.loading.save = false;
      }
    },
    emitUpdates() {
      const manifestId = this?.myManifestId;
      if (this.isFromMap)
        this.$store.commit('map/response', { type: 'dispatch/updateManifestEvents', manifestId });
      this.$store.commit('dispatch/updateManifestEvents');
      this.$store.commit('deliveries/reloadTable');
      this.$emit('saved');
    },
    async onSubmit() {
      this.loading.save = true;
      if (this.validator()) {
        try {
          await this.handleSaveParticipant();
          if (
            this.action.isEdit &&
            this.show.formAddressPicker &&
            Object.prototype.hasOwnProperty.call(this.data.newAddress, 'address')
          ) {
            await this.editAddress();
          }
          if (this.action.isAdd && this.show.formAddressPicker && !this.address.id) {
            await this.saveAddress();
          } else {
            await this.handleSaveDelivery();
          }
        } catch (error) {
          console.log(error);
        }
      }
      this.loading.save = false;
    },
    clean() {
      this.address = {};
      this.addresses = [];
      this.oDelivery = {};
      this.disabled = {
        addParticipantButton: true,
        buttonIsAddress: true
      };
      this.deliveryAddress = '';
      this.show = {
        formAddressPicker: false
      };
      this.data = {
        participant: {},
        newAddress: {},
        idAddress: null
      };
      this.action = {
        isEdit: false,
        isAdd: false
      };
      this.hasParticipantError = false;
      this.hasRestriction = false;
      this.deliveryTimes = null;
      this.isModalOpen = false;
      this.rush = false;
      this.leaveAtDoor = false;
      this.priority = false;
      this.customParticipant = null;
    },
    prepareData() {
      const { oDelivery: D, rush, deliveryTimes, hasRestriction, priority, leaveAtDoor } = this;
      const { delivery_start_time, delivery_end_time } = hasRestriction ? deliveryTimes : {};
      const addressId = this.currentAddressId;
      const delivery_date = this.$moment(D.delivery_date).format('Y-MM-DD');
      const common_payload = {
        comments: D.comments,
        delivery_end_time: delivery_end_time || null,
        delivery_start_time: delivery_start_time || null,
        participant_id: this.participantId,
        rush: rush ? 1 : 0,
        priority: priority ? 1 : 0,
        leave_at_door: leaveAtDoor ? 1 : 0,
        refrigeration_needed: D.refrigeration_needed,
        tracking_number: D.tracking_number,
        is_collect_event: D.is_collect_event ? 1 : 0,
        copayment_amount: D.is_collect_event ? 0 : D.copayment_amount
      };
      if (this.isAddOn) {
        return {
          ...common_payload,
          delivery_event_type_id: D.is_collect_event ? 8 : 3,
          destination_id: addressId,
          secuence: null
        };
      } else {
        return {
          ...common_payload,
          participant_address_id: addressId,
          delivery_date,
          close_door_pharmacy_id: D.close_door_pharmacy_id,
          mobile_phone_number_id: this.mobilePhoneNumberId,
          phone_number_id: this.phoneNumberId,
          deliveries_import_id: this.deliveryImport || this.delivery?.deliveries_import_id || null
        };
      }
    },
    async saveAddress() {
      this.loading.save = true;
      const participant_id = this.customParticipant?.temp?.id || this.oDelivery.participant_id;
      try {
        const params = {
          name: 'NO NAME',
          address: this.address.address,
          address_1: this.address.address_1,
          state: this.address.state,
          city: this.address.city,
          gate_code: this.address.gate_code,
          zipcode: this.address.zipcode,
          latitude: this.address.latitude || 0,
          longitude: this.address.longitude || 0,
          formatted_address: this.address.formatted_address || ''
        };
        const { data } = await this.Api.post(`participants/${participant_id}/addresses`, params);
        if (data) {
          this.setAddress(data.id, data.full_address);
          await this.handleSaveDelivery();
          this.address = {};
        }
      } catch (error) {
        console.error(error);
        this.loading.save = false;
      }
    },
    async saveAddOnDelivery(isOverride, body) {
      try {
        let payload = { ...body };
        delete payload.is_collect_event;

        this.Api.ErrorProcess = false;
        await this.Api.post(
          `delivery_manifest/${this.myManifestId}/delivery_events_addon${
            isOverride ? '_override' : ''
          }`,
          payload
        );
      } catch (error) {
        if (error?.status_code === RSC.manifest_force_overtime) {
          await confirm({
            title: this.$t('confirms.continue'),
            message: this.$t('schedule.confirm.overtime'),
            cancelText: this.$t('confirm.no'),
            confirmText: this.$t('confirm.yes'),
            onConfirm: async () => {
              await this.saveAddOnDelivery(true, body);
            }
          });
        } else {
          throw error;
        }
        exceptionMessage({
          error,
          messageError: Normalize(this.$t('global.duplicatedOrderNumber'), { upper: true }),
          callBack: this.restoreErrorOrderNumber
        });
      }
    },
    async saveDelivery(payload) {
      const { id } = this.oDelivery;
      const method = id ? 'put' : 'post';
      const url = `/delivery_manifest/unattended_deliveries${id ? `/${id}` : ''}`;
      this.Api.ErrorProcess = false;
      const { data } = await this.Api[method](url, payload);
      return data;
    },
    async handleSaveDelivery() {
      this.loading.save = true;
      try {
        const payload = this.prepareData();
        let result = null;
        if (this.isAddOn) result = await this.saveAddOnDelivery(false, payload);
        else result = await this.saveDelivery(payload);
        this.oDelivery = this.oDelivery.id ? result : {};
        this.$emit('patchReady', result);
        this.emitUpdates();
        this.clean();
        if (this.isModalOpen) this.isModalOpen = false;
        toast('success', this.$t('messages.saved'), 5000);
      } catch (error) {
        exceptionMessage({
          error,
          messageError: Normalize(this.$t('global.duplicatedOrderNumber'), { upper: true }),
          callBack: this.restoreErrorOrderNumber
        });
      }
      this.loading.save = false;
    },
    restoreErrorOrderNumber() {
      this.$refs.order_number.isErrorOrderNumber = true;
    },
    setAddress(address_id, full_address) {
      if (address_id) {
        this.data.idAddress = address_id;
        this.oDelivery.participant_address_id = address_id;
        this.deliveryAddress = full_address;
      }
    },
    validateAddress() {
      const { addressPicker } = this.$refs;
      return addressPicker ? addressPicker?.validate() : true;
    },
    validateFrom() {
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return false;
      }
      return true;
    },
    validateRestrictions() {
      if (!this.hasRestriction) return true;
      if (this.deliveryTimes) {
        return true;
      } else {
        toast('warning', this.$t('messages.deadlines'), 5000);
        return false;
      }
    },
    validator() {
      if (!this.validateFrom()) return false;
      if (!this.validateRestrictions()) return false;
      if (!this.validateAddress()) return false;
      return true;
    },
    namesParser(full_name = '') {
      const fullName = Normalize(full_name, {
        replace: { find: ['.', ',', multipleSpacesRegex], value: [' '] }
      });
      const names = fullName.trim().split(' ');
      const lastName = names.pop();
      return {
        participant_first_name: names.join(' '),
        participant_last_name: lastName
      };
    },
    validateDate() {
      const { delivery_date } = this.oDelivery;
      const diff = this.$moment().diff(delivery_date, 'days');
      const validDate = diff <= 0;
      if (!validDate) {
        toast({ type: 'info', message: this.$t('messages.validateDDate') });
        this.setDefaultDate();
      }
    },
    async onDeliveryUpdate(delivery) {
      this.oDelivery = { ...delivery };
      this.validateDate();
      const {
        id,
        participant_address_id,
        participant_address,
        participant_full_name,
        participant_id,
        phone_number_id,
        mobile_phone_number_id,
        participant_phone_number,
        rush,
        leave_at_door,
        priority
      } = delivery;
      this.currentDeliveryParticipantId = participant_id;
      //! inconsistencia en los nombres
      const participant_mobile_phone_number_id = mobile_phone_number_id;
      const participant_mobile_phone_number =
        delivery.mobile_phone_number ||
        delivery.participant_mobile_number ||
        delivery.participant_mobile_phone_number;
      if (delivery?.delivery_start_time || delivery?.delivery_end_time) {
        this.hasRestriction = true;
      } else {
        this.hasRestriction = null;
      }
      if (id) this.getAddresses();
      if (participant_address) {
        this.deliveryAddress = delivery.participant_full_address;
        this.address.address = participant_address;
        this.hasParticipantError = true;
        this.action.isAdd = true;
      }

      this.rush = rush;
      this.leaveAtDoor = leave_at_door;
      this.priority = priority;

      if (participant_id) {
        this.data.idAddress = participant_address_id;
        this.loading.participant = true;
        const { data } = await this.Api.get(`participants/${participant_id}`);
        this.loading.participant = false;
        this.data.participant = {
          ...data,
          participant_phone_number_id: phone_number_id,
          participant_mobile_phone_number_id,
          participant_phone_number,
          participant_mobile_phone_number
        };
        this.disabled.buttonIsAddress = false;
        this.disabled.addParticipantButton = false;
      } else {
        let phones = {};
        if (participant_mobile_phone_number) {
          phones = { participant_phone_number, participant_mobile_phone_number };
        }
        const names = this.namesParser(participant_full_name);
        this.data.participant = {
          ...this.data.participant,
          ...phones,
          ...names
        };
      }
    },
    assignParticipantData() {
      this.getAddresses();
      this.data.participant = { ...this.participant };
    }
  },
  watch: {
    isOpen(value) {
      if (!value) this.clean();
      this.isModalOpen = value;
      this.show.formAddressPicker = false;
    },
    isModalOpen(value) {
      this.setDefaultDate();
      if (value && this.participant) {
        this.oDelivery.participant_id = this.participant.id;
        this.oDelivery.participant_full_name = this.participant.participant_full_name;
        if (this.isFromParticipants) this.assignParticipantData();
      } else if (this.oDelivery.id) this.oDelivery = { ...this.delivery };
      this.$emit('update:isOpen', value);
    },
    async delivery(delivery) {
      this.onDeliveryUpdate(delivery);
    },
    'show.formAddressPicker'(value) {
      if (value && !this.action.isEdit) {
        this.setAddress(null, null);
        this.disabled.buttonIsAddress = true;
      }
    },
    manifestId(value) {
      this.myManifestId = value;
    },
    address(value) {
      if (value.comment)
        this.oDelivery.comments = `${value?.comment || ''}\n${this.oDelivery.comments || ''}`;
    }
  },
  props: {
    delivery: { type: Object, default: () => {} },
    isAddOn: { type: Boolean, default: false },
    isFromMap: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false },
    manifestId: { type: Number, default: null },
    participant: { type: Object, default: null },
    deliveryImport: { type: String, default: null },
    isFromParticipants: { type: Boolean, default: false },
    scope: {
      type: String,
      validator: (value) => ['SCHEDULE', 'DISPATCH', 'IMPORT'].includes(value),
      default: null
    }
  }
};
</script>

<style lang="sass" scoped>
#deliveryModal
  .deliverySequence
    margin-top: 1rem
    flex-wrap: wrap
  .cpdField
    ::v-deep
      .dropdown-menu
        max-width: 100%!important
        .cdpResult small
          white-space: normal
  .order-number-text
    color: $red-800
    font-size: 12px
  .address-picker
    ::v-deep
      .ap-address
        width: 100%
        margin-right: 0
      .ap-address1,
      .ap-zipcode,
      .ap-state
        width: calc(30% - .1rem)
      .ap-city
        width: 68%
        margin-right: 0
      .ap-gate-code
        width: 29%
</style>
